import { createApp } from "vue";

import Gallery from "./lgsGallery.vue";
frappe.provide('lgs')

lgs.maintenanceImageGallery = class maintenanceImagesGallery {
	constructor(opts) {
		Object.assign(this, opts)
		this.wrapper = $(this.frm.wrapper).find("#maintenance-image-gallery")[0];
		this.make()
	}
	
	make() {
		frappe.call("lagrandeserre.la_grande_serre.doctype.subscriber_lgs.subscriber_lgs.get_maintenance_images",
			{subscriber: this.subscriber, maintenance: this.maintenance})
		.then(r => {
			if (r.message.length) {
				this.display_files(r.message);
			}
		})
	}

	display_files(data) {
		const app = createApp(Gallery, {
			frm: this.frm,
			docs: data,
			small: this.small
		})
		window.SetVueGlobals(app);
		app.mount(this.wrapper);
	}
}