<template>
  <div>
    <template v-if="!docs.length">
      <div class="gallery" v-if="images.length">
        <div class="gallery-panel" v-for="image in verified_files(images)" :key="image.name">
          <a :href="image.file_url" target="_blank"><img :src="image.file_url"></a>
          <div class="file-remove" @click="remove(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="small">{{ __("No images for this document") }}</p>
      </div>
    </template>
    <template v-else>
      <div v-for="doc in docs" :key="doc.name">
        <p v-if="docs.length > 1" class="muted small">{{ frappe.datetime.obj_to_user(doc.start_datetime) }} : {{ doc.gardener }}</p>
        <template v-if="verified_files(doc.files).length">
          <div :class="small ? 'gallery small-images' : 'gallery'">
            <div class="gallery-panel" v-for="image in verified_files(doc.files)" :key="image.name">
              <a :href="image.file_url" target="_blank"><img :src="image.file_url"></a>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="small">{{ __("No images for this maintenance") }}</p>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Gallery',
  props: {
    frm: {
      type: Object,
      default: () => {}
    },
    docs: {
      type: Array,
      default: () => []
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      images: []
    }
  },
  created() {
    if (!this.docs.length) {
       lgs.gallery_update.on("update", () => {
        this.get_files()
      })
    }
  },
  mounted() {
    if (!this.docs.length) {
      this.get_files();
    }
  },
  methods: {
    get_files() {
      this.frm&&this.frm.sidebar.reload_docinfo((docinfo) => {
        this.images = docinfo.attachments;
      })
    },
    remove(file) {
      lgs.gallery_update.trigger('remove', file);
      this.images = this.images.filter(f => f.name != file.name);
    },
    verified_files(files) {
      return files.filter(f => (f.file_url.includes('.png') || f.file_url.includes('.jpeg') || f.file_url.includes('.jpg')));
    }
  }
};
</script>
<style>
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 1rem;
    margin: 2rem auto;
  }

  .gallery.small-images {
    grid-template-columns: repeat(auto-fill, 150px);
  }

  .gallery-panel {
    position: relative;
  }

  .gallery-panel img {
    width: 100%;
    /*min-height: 12vw;*/
    object-fit: cover;
    border-radius: 0.75rem;
  }

  .file-remove {
    position: absolute;
    top: -7px;
    right: 7px;
    background: #2e3338;
    color: white;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
  }
</style>