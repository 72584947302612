import { createApp } from "vue";

import Gallery from "./lgsGallery.vue";
frappe.provide('lgs')

lgs.imageGallery = class imageGallery {
	constructor(frm) {
		this.frm = frm
		lgs.gallery_update = {};
		frappe.utils.make_event_emitter(lgs.gallery_update);
		this.make()
	}

	make() {
		lgs.gallery_update.on('remove', (file) => {
			this.frm.attachments.remove_attachment(file.name, () => {
				this.frm.reload_doc();
			})
		})

		this.draw();
	}

	draw() {
		const app = createApp(Gallery, {
			frm: this.frm,
			docs: []
		})
		window.SetVueGlobals(app);
		app.mount(this.frm.fields_dict.images_html.$wrapper.find("#image-gallery")[0]);
	}

	add_image() {
		if (this.frm.attachments.dialog) {
			this.frm.attachments.dialog.$wrapper.remove();
		}

		new frappe.ui.FileUploader({
			doctype: this.frm.doctype,
			docname: this.frm.docname,
			folder: 'Home/Attachments',
			on_success: (file_doc) => {
				this.frm.attachments.attachment_uploaded(file_doc);
				lgs.gallery_update.trigger("update");
			}
		});
	}
	
}